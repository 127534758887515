import React, { } from 'react';
import cheerio from 'cheerio-without-node-native';

const baseURL = "https://hn.shiftedbits.org";

const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const makeUser = () => {
    const userCookie = localStorage.getItem("user");
    if (userCookie === null) return null;

    return {
      name: userCookie.split("&")[0],
      cookie: `user=${userCookie}`,
    }
  };

  const [user, setUser] = React.useState(makeUser());

  const userLogin = async ({ username, password }) => {
    localStorage.removeItem("user");

    const resp = await fetch(`${baseURL}/hn/login`, {
      method: 'POST',
      cache: 'no-cache',
      body: new URLSearchParams({
        acct: username,
        pw: password,
      }),
    });

    if (resp.status !== 302) {
      // bad login, a login page has been returned
      return null;
    }

    const cookie = resp.headers.get('X-Custom-Cookie')?.split(';')[0].split('=')[1];
    localStorage.setItem("user", cookie);
    const u = makeUser();
    setUser(u);
    return u;
  }

  const userLogout = () => {
    localStorage.removeItem("user");
    setUser(makeUser());
  }

  const getHMAC = async (item) => {
    const url = `${baseURL}/hn/item?id=${item}`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'X-Custom-Cookie': user.cookie,
      }
    });

    const text = await resp.text();
    const doc = cheerio.load(text);
    const hmac = doc('input[name=hmac]').attr('value');
    return hmac;
  }

  const postComment = async (comment) => {
    // first, fetch the comment reply form and extract hmac param
    const hmac = await getHMAC(comment.parent);
    await fetch(`${baseURL}/hn/comment`, {
      method: 'POST',
      headers: {
        'X-Custom-Cookie': user.cookie,
      },
      redirect: 'manual',
      cache: 'no-cache',
      body: new URLSearchParams({
        parent: comment.parent,
        hmac: hmac,
        text: comment.text,
        goto: `item?id=${comment.parent}`,
      })
    });
  };

  const value = {
    postComment,
    user,
    userLogin,
    userLogout,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };