import React from "react";
import { Box, Flex, Text, Stack, Button } from "@chakra-ui/react";
import { Link, useRouteMatch } from 'react-router-dom';
import { UserContext } from './user';

const NavBar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { user, userLogout } = React.useContext(UserContext);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Box maxW="container.xl" width="100%">
        <Stack justify="space-between" direction="row">
          <MenuToggle toggle={toggle} isOpen={isOpen} />
          <MenuLinks isOpen={isOpen} close={toggle} />
          <Box>
            {user &&
              <Stack justify="start" direction="row" alignItems="center">
                <Text>{user.name}</Text>
                <Button display={{base: 'none', md: "block"}} variant="ghost" size="xs" onClick={userLogout}>Logout</Button>
              </Stack>
            }
            {!user &&
              <Link to="/login">
                <Button display={{base: 'none', md: "block"}} variant="ghost" size="xs">Login</Button>
              </Link>
            }
          </Box>
        </Stack>
      </Box>
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg width="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="white">
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, close, selected=false, to = "/", ...rest }) => {
  return (
    <Link to={to}>
      <Text display="block" fontWeight={selected ? "bold" : "normal"} onClick={close} {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen, close }) => {
  const { name } = useRouteMatch('/collection/:name')?.params || { name: ''};
  const { user, userLogout } = React.useContext(UserContext);

  const logout = () => {
    close();
    userLogout();
  }

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={[2, 8, 8, 8]}
        align="center"
        justify={["center", "space-between", "flex-start", "flex-start"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to="/collection/top" selected={name === 'top'} close={close}>Top</MenuItem>
        <MenuItem to="/collection/new" selected={name === 'new'} close={close}>New</MenuItem>
        <MenuItem to="/collection/best" selected={name === 'best'} close={close}>Best</MenuItem>
        <MenuItem to="/collection/ask" selected={name === 'ask'} close={close}>Ask</MenuItem>
        <MenuItem to="/collection/show" selected={name === 'show'} close={close}>Show</MenuItem>
        <MenuItem to="/collection/job" selected={name === 'job'} close={close}>Jobs</MenuItem>
        {!user &&
          <MenuItem display={{base: 'block', md: 'none'}} to="/login" close={close}>Login</MenuItem>
        }
        {user &&
          <Text display={{base: 'block', md: 'none'}} onClick={logout}>Logout</Text>
        }
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="center"
      wrap="wrap"
      w="100%"
      mb={8}
      p={2}
      bg={["#ff6600", "#ff6600", "#ff6600", "#ff6600"]}
      color={["white", "white", "white", "white"]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default NavBar;