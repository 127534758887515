import './App.css';
import { Grid, Text, Box, Container, CircularProgress, InputGroup, Input, InputRightElement, Flex, Button,
  FormControl, FormLabel, FormErrorMessage, Alert, AlertIcon, AlertTitle, chakra, CloseButton } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { Route, Switch, withRouter, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import Story from './story';
import NavBar from './nav';
import { RadioButtons } from './radios';
import { UserContext } from './user';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const FetchAllItems = async (items) => {
  return await Promise.all(
    items.map(item =>
      fetch(`https://hacker-news.firebaseio.com/v0/item/${item}.json`).then((response) => response.json())
    )
  )
};

const FetchCollection = async (name) => {
  const url = `https://hacker-news.firebaseio.com/v0/${name}stories.json`;
  const items = await (await fetch(url)).json();
  const allStories = await FetchAllItems(items);
  const validStories = allStories.filter(a => a !== null);

  validStories.forEach((s, idx) => {
    // ask items are mislabled as stories
    if (s.type === 'story' && s.url === undefined) {
      s.type = 'ask';
    }
    // supply a url for ASK items
    s.url = s.url || `/story/${s.id}`
    s.local = s.url[0] === '/'

    s.descendants = s.descendants || 0
    s.index = idx
  });

  return {
    stories: validStories,
  };
};

const borderProps = {
  borderBottom: '1px',
  borderTop: '1px',
  borderLeft: {base: 'none', md: '1px'},
  borderRight: {base: 'none', md: '1px'},
  borderColor: {base: '#BBB', md: '#BBB' },
};

const CommentHeader = ({ comment }) => {
  const date = DateTime.fromMillis(comment.time * 1000);

  return (
    <>
      <span style={{fontWeight: 'bold'}}>{comment.by}</span><span> on </span>
      {date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at {date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
    </>
  )
}

const StoryItem = ({ story }) => {
  return (
    <Grid templateRows="auto auto" {...borderProps}>
      <Box bg="#F9F9F9" borderBottom="1px solid #BBB" padding="5px">
        <Text fontSize="15px" textAlign="right">
          <CommentHeader comment={story} />
        </Text>
      </Box>
      <Grid templateColumns={{base: "auto", md: "minmax(200px, auto) auto"}} columnGap="20px">
        <Box padding="5px" className="story-item">
          <a target="_blank" rel="noreferrer" href={story.url}><Text fontSize="20px">{story.title}</Text></a>
          {!story.local &&
            <chakra.a href={story.url} target="_blank" rel="noreferrer"><Text fontSize="16px" color="#666" maxW={{base: "calc(100vw - 15px)", md: "100%"}} isTruncated>{story.url}</Text></chakra.a>
          }
        </Box>
        {story.descendants !== undefined &&
          <Box padding="5px" justifySelf={{base: "stretch", md: "end"}} alignSelf="center">
            <Link to={`/story/${story.id}`} target="_blank" rel="noreferrer"><Button width={{base: "100%", md:""}}>{story.descendants} comments</Button></Link>
          </Box>
        }
      </Grid>
    </Grid>
  )
}

const Collection = () => {
  const { name } = useRouteMatch('/collection/:name')?.params || { name: 'top'};
  const [data, setData] = React.useState({});
  const [sortKey, setSortKey] = React.useState('Default');
  const [isLoading, setIsLoading] = React.useState(true);

  const doSort = (stories) => {
    if (sortKey === 'Default') {
      stories.sort((a, b) => a.index - b.index);
    } else if (sortKey === 'Recent') {
      // sort reverse chronological
      stories.sort((a, b) => b.time - a.time);
    } else if (sortKey === 'Active') {
      stories.sort((a,b) => b.descendants - a.descendants);
    }
  };

  React.useEffect(() => {
    const doFetch = async () => {
      const values = await FetchCollection(name);
      doSort(values.stories);
      setData(values);
      setIsLoading(false);
      document.title = `HN Viewer - ${name}`;
    };

    setIsLoading(true);
    doFetch();
  }, [ name ]);

  React.useEffect(() => {
    if (data?.stories !== undefined) {
      const data2 = {...data};
      doSort(data2.stories);
      setData(data2);
    }
  }, [ sortKey ]);

  const changeSort = (sort) => {
    setSortKey(sort);
  };

  const padding = {base: '15px', md: '0px'};

  return (
    <Box width="100%">
      <Flex justify="flex-end" marginBottom="20px" width="100%" paddingInlineStart={padding} paddingInlineEnd={padding}>
        <RadioButtons options={["Default", "Recent", "Active"]} onChange={changeSort} defaultValue={sortKey} name="sort" />
      </Flex>

      {isLoading &&
        <Flex width="100%" justify="center">
          <CircularProgress isIndeterminate color="#ff6600" size="100" />
        </Flex>
      }

      {!isLoading &&
        <Grid gap={3} maxW="100vw">
          { data?.stories && data.stories.map((story) => <StoryItem key={story.id} story={story} />) }
        </Grid>
      }
    </Box>
  )
}

const Login = () => {
  const { userLogin } = React.useContext(UserContext);
  const [ showPw, setShowPw ] = React.useState(false);
  const handleShowPw = () => setShowPw(!showPw);
  const [ invalidLogin, setInvalidLogin ] = React.useState(false);

  const schema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  const submit = async (values, actions) => {
    const result = await userLogin(values);
    actions.setSubmitting(false);

    if (result !== null) {
      window.location = '/collection/top';
    } else {
      setInvalidLogin(true);
    }
  }

  return (
    <Formik onSubmit={submit} validationSchema={schema} initialValues={{username: '', password: ''}}>
      { ({errors, touched, isSubmitting}) => (
        <Form>
          {invalidLogin &&
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Invalid login.</AlertTitle>
              <CloseButton position="absolute" right="8px" top="8px" onClick={setInvalidLogin.bind(null, false)}></CloseButton>
            </Alert>
          }
          <Field name="username">
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.username && form.touched.username}>
                <FormLabel htmlFor="username">Username</FormLabel>
                <Input {...field} id="username" type="text" placeholder="Enter username"></Input>
                <FormErrorMessage>{form.errors.username}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="password">
            {({ field, form }) => (
              <FormControl id="password" isInvalid={form.errors.password && form.touched.password}>
                <FormLabel>Password</FormLabel>
                <InputGroup size="md">
                  <Input {...field} id="password" pr="4.5rem" type={showPw ? "text" : "password"} placeholder="Enter password" />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowPw}>
                      {showPw ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button mt={4} isLoading={isSubmitting} type="submit">Login</Button>
        </Form>
      )}
    </Formik>
  )
}

const WrappedNavBar = withRouter(NavBar);

function App() {

  return (
    <div className="App">
      <WrappedNavBar />
      <Container maxW="container.xl" centerContent paddingInlineStart={{base: "0px", md: "15px"}} paddingInlineEnd={{base: "0px", md: "15px"}}>
        <Switch>
          <Route path="/collection/:name" children={<Collection />} />
          <Route path="/story/:id" children={<Story />} />
          <Route path="/login" exact children={<Login />} />
          <Route path="/" exact children={<Collection />} />
        </Switch>
      </Container>
    </div>
  );
}

export default App;
