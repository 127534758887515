import React, { useState } from 'react';
import './App.css';
import { Grid, Text, Box, Button, Flex, CircularProgress, Textarea, useMediaQuery } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router';
import { UserContext } from './user';

const FetchStoryDataAlgolia = async (storyId) => {
  const story = await (await fetch(`https://hn.algolia.com/api/v1/items/${storyId}`)).json();

  const rawcomments = story.children || [];
  const comments = [];

  while (rawcomments.length) {
    const raw = rawcomments.shift();
    rawcomments.push(...raw.children);

    if (!raw.author) continue;

    comments.push({
      by: raw.author,
      time: raw.created_at_i,
      id: raw.id,
      parent: raw.parent_id === story.id ? null : raw.parent_id,
      text: raw.text,
      children: (raw.children || []).map((c) => c.id),
    });
  }

  // sort chronological
  comments.sort((a,b) => a.time - b.time);

  const byId = Object.assign({}, ...comments.map((x) => ({[x.id]: x})));
  const newStory = {
    by: story.author,
    time: story.created_at_i,
    id: story.id,
    parent: null,
    title: story.title,
    url: story.url,
    text: story.text,
  };

  return {
    story: newStory,
    comments: comments,
    context: {
      byId: byId,
      story: newStory,
    }
  }
};

const borderProps = {
  borderBottom: '1px',
  borderTop: '1px',
  borderLeft: '1px',
  borderRight: '1px',
  borderColor: '#BBB',
};

const CommentBody = ({ comment, indent=0, ...props }) => {
  return (
    <Box bg="white" className="comment-body" {...props} fontStyle={indent !== 0 ? "italic" : "normal"} fontSize={indent !== 0 ? "15px" : "16px"}>
      <Text as="div" textAlign="left" dangerouslySetInnerHTML={{__html: comment.text}}></Text>
    </Box>
  )
};

const CommentReply = ({ comment, context, indent=0 }) => {
  const date = DateTime.fromMillis(comment.time * 1000);
  const [collapsed, setCollapsed] = useState(indent === 4);

  const expand = () => setCollapsed(false);

  return (
    <Grid templateRows="auto auto" width="100%" {...borderProps} marginBottom={indent !== 1 ? "5px" : "30px"}>
      <Box bg="#F6F6F6" borderBottom="1px solid #CCC" padding="5px">
        <Text textAlign="left" fontWeight="bold" fontSize="12px">{comment.by} said on {date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} at {date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}</Text>
      </Box>
      <CommentContents comment={comment} context={context} indent={indent} hidden={collapsed} />
      {collapsed && indent === 4 &&
        <Box padding="5px">
          <Button onClick={expand} variant="link">Show Contents</Button>
        </Box>
      }
    </Grid>
  )
};

const CommentContents = ({ comment, context, indent=0, ...props }) => {
  return (
    <Box paddingTop="15px" paddingBottom="15px" paddingLeft="15px" paddingRight="2px" {...props}>
      {comment.parent != null && comment.parent in context.byId &&
        <CommentReply comment={context.byId[comment.parent]} indent={indent+1} context={context} />
      }
      <CommentBody comment={comment} indent={indent} {...props} />
    </Box>
  )
};

const CommentHeader = ({ comment }) => {
  const date = DateTime.fromMillis(comment.time * 1000);
  const [isLarge] = useMediaQuery("(min-width: 48em)");

  return (
    <Grid justifyContent="space-between" templateColumns="auto auto" id={comment.id ?? comment.tempid}>
      <Text fontSize="14px">
        {comment.id &&
          <a href={`#${comment.id}`}>#{comment.id}</a>
        }
      </Text>
      <div>
        <Text as="span" display={{base: "inline", md: "none"}}>
          <span style={{fontWeight: 'bold'}}>{comment.by}</span><span> on </span>
        </Text>
        {date.toLocaleString(!isLarge ? DateTime.DATE_SHORT : DateTime.DATE_MED_WITH_WEEKDAY)} at {date.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
      </div>
    </Grid>
  )
}

const borderPropsOuter = {
  ...borderProps,
  borderLeft: {base: 'none', md: '1px'},
  borderRight: {base: 'none', md: '1px'},
  borderColor: {base: '#BBB', md: '#BBB' },
};

const ReplyBox = ({ comment, context }) => {
  const textArea = React.useRef();
  const [ replyContents, setReplyContents ] = React.useState();
  const { user } = React.useContext(UserContext);

  const showReplyField = () => {
    setReplyContents(
      <Textarea placeholder="Enter your reply" ref={textArea} />
    )
  }

  const hideReplyField = () => {
    setReplyContents(undefined);
  }

  const submitReply = () => {
    const reply = {
      tempid: `reply_${comment.id}`,
      text: textArea.current.value,
      by: user.name,
      time: DateTime.now().toMillis() / 1000,
      parent: comment.id,
      story: context.story.id,
    };
    context.addReply(reply);

    hideReplyField();
  }

  return (
    <Box borderTop={borderProps.borderTop} borderColor="#DDD" padding="5px">
      {!replyContents &&
        <Grid templateColumns="auto" justifyContent="end">
          <Button variant="ghost" size="xs" onClick={showReplyField}>Reply</Button>
        </Grid>
      }
      {replyContents &&
        <Box paddingTop="10px" paddingLeft="10px" paddingRight="10px">
          <div>
            {replyContents}
          </div>
          <Grid templateColumns="auto auto" gap={2} justifyContent="end">
            <Button size="xs" onClick={hideReplyField}>Cancel</Button>
            <Button size="xs" onClick={submitReply}>Submit</Button>
          </Grid>
        </Box>
      }
    </Box>
  )
}

const Comment = ({ comment, context }) => {
  return (
    <Grid templateColumns={{base: "auto", md: "150px auto"}} gap={0} {...borderPropsOuter}>
      <Box bg="#EEE" borderRight="1px solid #BBB" paddingTop="40px" display={{base: "none", md: "block"}}>
        <Text fontWeight="bold" textAlign="center">{comment.by}</Text>
      </Box>
      <Grid templateRows="auto auto" gap={0}>
        <Box bg="#F9F9F9" borderBottom="1px solid #DDD" padding="5px" fontSize="15px" textAlign="right">
          <CommentHeader comment={comment} />
        </Box>
        <CommentContents comment={comment} context={context} />
        {context.user && comment.id && <ReplyBox comment={comment} context={context}/> }
      </Grid>
    </Grid>
  )
};

const TitlePost = ({ story, context }) => {
  return (
    <Grid templateColumns={{base: "auto", md: "150px auto"}} gap={0} {...borderPropsOuter} marginBottom="40px">
      <Box bg="#EEE" borderRight="1px solid #BBB" paddingTop="40px" display={{base: "none", md: "block"}}>
        <Text fontWeight="bold" textAlign="center">{story.by}</Text>
      </Box>
      <Grid templateRows="auto auto" gap={0}>
        <Box bg="#F9F9F9" borderBottom="1px solid #DDD" padding="5px" fontSize="15px" textAlign="right">
          <CommentHeader comment={story} />
        </Box>
        <Box padding="15px" className="comment-body">
          <Text fontSize="20px">{story.title}</Text>
          {story.url &&
            <Text fontSize="16px"><a href={story.url}>{story.url}</a></Text>
          }
          {story.text &&
            <CommentBody comment={story}/>
          }
        </Box>
        {context.user && <ReplyBox comment={story} context={context}/> }
      </Grid>
    </Grid>
  )
};

function Story() {
  const { id } = useParams();
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [replies, setReplies] = React.useState([]);
  const { postComment, user } = React.useContext(UserContext);

  const addReply = async (reply) => {
    const doAddReply = async () => {
      await postComment(reply);
      setReplies([...replies, reply]);
      setTimeout(() => {
        window.location.hash = `#${reply.tempid}`;
      }, 100);
    };
    doAddReply();
  };

  React.useEffect(() => {
    const doFetch = async () => {
      const values = await FetchStoryDataAlgolia(id);
      values.context.addReply = addReply;
      values.context.user = user;
      setData(values);
      setIsLoading(false);
      document.title = values.story.title;
    };
    doFetch();
  }, [ id, user ]);

  return (
    <>
      {isLoading &&
        <Flex width="100%" justify="center">
          <CircularProgress isIndeterminate color="#ff6600" size="100" />
        </Flex>
      }
      {!isLoading &&
        <Grid templateColumns="auto" maxW="100vw" gap={3}>
          { data?.story && <TitlePost story={data.story} context={data.context} /> }
          { data?.comments?.map((comment) => <Comment key={comment.id} comment={comment} context={data.context} />) }
          { replies.map((comment) => <Comment key={comment.tempid} comment={comment} context={data.context} />)}
        </Grid>
      }
    </>
  );
}

export default Story;
